<template>
  <div class="boutique-container">
    <div class="title-select d-f a-c j-b">
      <div class="d-f a-c">
        <div class="line"></div>
        <div class="title">精品资源</div>
      </div>
      <div class="d-f">
        <div
          :class="active == index ? 'select-checked' : 'select'"
          v-for="(item, index) in list"
          :key="item.id"
          @click="select(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content d-f f-w j-b">
      <div
        class="content-item d-f a-c"
        v-for="item in boutiqueListTop"
        :key="item.id"
        @click="toPreview(item)"
      >
        <img
          src="@/assets/image/boutique_icon_word.png"
          v-if="item.fileTypeName === 'word'"
        />
        <img
          src="@/assets/image/boutique_icon_ppt.png"
          v-else-if="item.fileTypeName === 'ppt'"
        />
        <img
          src="@/assets/image/boutique_icon_pdf.png"
          v-else-if="item.fileTypeName === 'pdf'"
        />
        <img
          src="@/assets/image/boutique_icon_video.png"
          v-else-if="item.fileTypeName === 'video'"
        />
        <img
          src="@/assets/image/boutique_icon_zip.png"
          v-else-if="item.fileTypeName === 'package'"
        />
        <img
          src="@/assets/image/boutique_icon_other.png"
          v-else-if="item.fileTypeName === 'other'"
        />
        <p :title="item.fileName">{{ item.fileName }}</p>
        <div class="time">{{ item.createTime | handleTime }}</div>
      </div>
      <div class="empty" v-if="canShow"></div>
      <div
        class="content-item d-f a-c"
        v-for="item in boutiqueListBottom"
        :key="item.id"
        @click="toPreview(item)"
      >
        <img
          src="@/assets/image/boutique_icon_word.png"
          v-if="item.fileTypeName === 'word'"
        />
        <img
          src="@/assets/image/boutique_icon_ppt.png"
          v-else-if="item.fileTypeName === 'ppt'"
        />
        <img
          src="@/assets/image/boutique_icon_pdf.png"
          v-else-if="item.fileTypeName === 'pdf'"
        />
        <img
          src="@/assets/image/boutique_icon_video.png"
          v-else-if="item.fileTypeName === 'video'"
        />
        <img
          src="@/assets/image/boutique_icon_zip.png"
          v-else-if="item.fileTypeName === 'package'"
        />
        <img
          src="@/assets/image/boutique_icon_other.png"
          v-else-if="item.fileTypeName === 'other'"
        />
        <p :title="item.fileName">{{ item.fileName }}</p>
        <div class="time">{{ item.createTime | handleTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      limit: 21,
      navId: null,
      boutiqueList: [],
      canShow: false,
      boutiqueListTop: [],
      boutiqueListBottom: [],
    };
  },
  filters: {
    handleTime(val) {
      let index = val.indexOf(" ");
      return val.slice(0, index);
    },
  },
  computed: {
    list() {
      this.navId = this.$ls.get("navList")[0].id;
      return this.$ls.get("navList");
    },
  },
  methods: {
    select(item, index) {
      this.active = index;
      this.navId = item.id;
      this.getBoutiqueList();
    },
    getBoutiqueList() {
      this.$api.getBoutiqueList({ limit: this.limit, navId: this.navId }).then((res) => {
        if (res.code === 200) {
          this.boutiqueList = res.data;
          if (this.boutiqueList.length > 12) {
            this.canShow = true;
            let arr = this.boutiqueList;
            this.boutiqueListTop = arr.splice(0, 12);
            this.boutiqueListBottom = arr;
          } else {
            this.canShow = false;
            this.boutiqueListTop = res.data;
            this.boutiqueListBottom = [];
          }
        } else {
          // console.log(res);
          this.$message.error(res.msg);
        }
      });
    },
    //去三级详情
    toPreview(item) {
      this.$router.push({
        path: "/preview",
        query: { navId: this.navId, id: item.id },
      });
    },
  },
  created() {
    let navId = this.$ls.get("navList")[0].id;
    this.$api.getBoutiqueList({ limit: this.limit, navId }).then((res) => {
      if (res.code === 200) {
        this.boutiqueList = res.data;
        if (this.boutiqueList.length > 12) {
          let arr = this.boutiqueList;
          this.canShow = true;
          this.boutiqueListTop = arr.splice(0, 12);
          this.boutiqueListBottom = arr;
        } else {
          this.canShow = false;
          this.boutiqueListTop = res.data;
          this.boutiqueListBottom = [];
        }
      } else {
        // console.log(res);
        this.$message.error(res.msg);
      }
    });
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.boutique-container {
  width: 100%;
  padding: 36px 120px 0px;
  .title-select {
    width: 100%;
    height: 40px;
    .line {
      width: 6px;
      height: 32px;
      background: @main-color;
      opacity: 1;
      border-radius: 3px;
    }
    .title {
      font-size: 30px;
      font-weight: 400;
      line-height: 40px;
      color: @main-color;
      opacity: 1;
      margin-left: 12px;
    }
    .select {
      height: 31px;
      padding: 0 15px;
      margin-left: 12px;
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      color: #333333;
      cursor: pointer;
    }
    .select-checked {
      height: 31px;
      padding: 0 15px;
      margin-left: 12px;
      background-image: url("~@/assets/image/boutique_select.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      color: @main-color;
      cursor: pointer;
    }
  }
  .content:after {
    content: "";
    display: block;
    width: 340px;
    height: 20px;
  }
  .content {
    width: 100%;
    height: 396px;
    background: #fff;
    margin-top: 12px;
    padding: 8px 24px 32px;
    box-sizing: border-box;
    align-content: flex-start;
    .empty {
      width: 100%;
      height: 36px;
    }
    .content-item {
      margin-top: 24px;
      width: 340px;
      height: 20px;
      cursor: pointer;
      img {
        width: 16px;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #0a0303;
        margin-left: 8px;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #999999;
        margin-left: 26px;
      }
    }
  }
}
</style>
