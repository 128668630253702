<template>
  <div class="search-container d-f a-c">
    <div class="logo">
      <img src="@/assets/image/search_logo.png" alt="" />
    </div>
    <div class="ipt">
      <a-input-search
        placeholder="请输入搜索关键词"
        enter-button
        @search="onSearch"
        size="large"
        class="ipt-con"
      >
        <a-select
          slot="addonBefore"
          class="ipt-select"
          default-value="test-questions"
          @change="getSelectValue"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            :value="item.identifier"
            v-for="item in navList"
            :key="item.identifier"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-input-search>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      identifier: "test-questions",
      navOneId: 2,
    };
  },
  computed: {
    navList() {
      return this.$ls.get("navList");
    },
  },
  methods: {
    //input的选择框变化
    getSelectValue(value) {
      let navList = this.$ls.get("navList");
      let navOneId = navList.find((r) => r.identifier === value).id;
      this.identifier = value;
      this.navOneId = navOneId;
    },
    //点击搜索图标按钮或者enter
    onSearch(value) {
      this.$ls.set("identifier", this.identifier, 60 * 60 * 1000 * 24);
      this.$ls.set("navOneId", this.navOneId, 60 * 60 * 1000 * 24);
      this.$ls.set("search", value, 60 * 60 * 1000 * 24);
      this.$ls.set("subjectId", null);
      this.$ls.set("gradeId", null);
      this.$router.push({
        path: "/secondary-details",
        query: { type: this.identifier },
      });
    },
  },
};
</script>

<style scoped lang="less">
.search-container {
  width: 1440px;
  height: 120px;
  background: #fff;
  padding: 0 120px;
  box-sizing: border-box;
  .logo {
    width: 256px;
    height: 94px;
    img {
      width: 100%;
    }
  }
  .ipt {
    margin-left: 100px;
    .ipt-con {
      width: 490px;
    }
    .ipt-select {
      width: 70px;
    }
  }
}
</style>
