<template>
  <div class="ppt-container">
    <div class="title-select d-f a-c j-b">
      <div class="d-f a-c">
        <div class="line"></div>
        <div class="title">{{ nickName }}</div>
      </div>
      <div class="grade d-f">
        <div
          :class="active == index ? 'select-checked' : 'select'"
          v-for="(item, index) in list"
          :key="item.id"
          @click="select(item, index)"
        >
          {{ item.name }}
        </div>
        <div class="more" @click="more">更多课件</div>
      </div>
    </div>
    <div class="content d-f f-w">
      <div
        class="content-item"
        v-for="item in pptList"
        :key="item.id"
        @click="toPreview(item)"
        :title="item.fileName"
      >
        <div class="ppt-img">
          <img src="@/assets/image/ppt_no_img.png" v-if="item.imgUrls == null" />
          <img :src="item.imgUrls[0]" v-else />
        </div>
        <p>{{ item.fileName }}</p>
        <div class="time">{{ item.createTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    nickName() {
      return this.$ls.get("navList")[2].nickName;
    },
    list() {
      return this.$ls.get("navList")[2].children;
    },
  },
  data() {
    return {
      pptList: [],
      subjectId: null,
      active: 0,
      pageSize: 10,
      imgSize: 1,
    };
  },
  methods: {
    //学科改变
    select(item, index) {
      this.active = index;
      this.subjectId = item.id;
      this.pptList = [];
      this.getPptList();
    },
    //更多课件
    more() {
      let navList = this.$ls.get("navList");
      this.$ls.set("identifier", "ppt", 60 * 60 * 1000 * 24);
      let navOneId = navList.find((r) => r.identifier === "ppt").id;
      this.$ls.set("navOneId", navOneId, 60 * 60 * 1000 * 24);
      this.$ls.set("subjectId", null);
      this.$ls.set("gradeId", null);
      this.$ls.set("search", null);
      this.$router.push({
        path: "/secondary-details",
        query: { type: "ppt" },
      });
    },
    //ppt课件列表
    getPptList() {
      this.$api.getPptList(this.subjectId, this.pageSize, this.imgSize).then((res) => {
        // console.log(res);
        if (res.code === 200) {
          this.pptList = res.data;
        } else {
          // console.log(res);
          this.$message.error(res.msg);
          this.pptList = [];
        }
      });
    },
    //去三级详情
    toPreview(item) {
      this.$router.push({
        path: "/preview",
        query: { navId: this.$ls.get("navList")[2].id, id: item.id },
      });
    },
  },
  created() {
    this.subjectId = this.$ls.get("navList")[2].children[0].id;
    this.getPptList();
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.ppt-container {
  width: 100%;
  padding: 36px 120px 88px;
  .title-select {
    width: 100%;
    height: 40px;
    .line {
      width: 6px;
      height: 32px;
      background: @main-color;
      opacity: 1;
      border-radius: 3px;
    }
    .title {
      font-size: 30px;
      font-weight: 400;
      line-height: 40px;
      color: @main-color;
      opacity: 1;
      margin-left: 12px;
    }
    .grade {
      margin-top: 12px;
      .select {
        height: 31px;
        padding: 0 15px;
        margin-left: 12px;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        color: #333333;
        cursor: pointer;
      }
      .select-checked {
        height: 31px;
        padding: 0 15px;
        margin-left: 12px;
        background-image: url("~@/assets/image/boutique_select.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        color: @main-color;
        cursor: pointer;
      }
      .more {
        margin-left: 12px;
        width: 72px;
        height: 24px;
        background: #ffffff;
        border: 1px solid @main-color;
        opacity: 1;
        border-radius: 2px;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        color: @main-color;
        cursor: pointer;
      }
    }
  }
  .content {
    width: 100%;
    height: 474px;
    background: #fff;
    margin-top: 12px;
    padding: 8px 0px 32px 30px;
    box-sizing: border-box;
    align-content: flex-start;
    .content-item {
      margin-top: 24px;
      margin-right: 30px;
      width: 204px;
      cursor: pointer;
      .ppt-img {
        width: 204px;
        height: 120px;
        background: #dbdbdb;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #333333;
        margin-top: 24px;
        width: 100%;
        height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        font-size: 12px;
        margin-top: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #999999;
        opacity: 1;
        font-size: 12px;
      }
    }
  }
}
</style>
