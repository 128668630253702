<template>
  <div class="teaching-plan-container">
    <div class="title-select d-f j-b">
      <div class="d-f a-c">
        <div class="line"></div>
        <div class="title">{{ nickName }}</div>
      </div>
      <div class="grade d-f">
        <div
          :class="active == index ? 'select-checked' : 'select'"
          v-for="(item, index) in list"
          :key="item.id"
          @click="select(item, index)"
        >
          {{ item.name }}
        </div>
        <div class="more" @click="more">更多教案</div>
      </div>
    </div>
    <div class="content d-f">
      <img :src="iconUrl" alt="" class="mian-img" />
      <div class="right d-f f-w">
        <div
          class="content-item"
          v-for="item in gradeList"
          :key="item.id"
          @click="goDetail(item)"
        >
          <div class="other-img">
            <img :src="item.iconUrl" alt="" />
          </div>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    nickName() {
      return this.$ls.get("navList")[1].nickName;
    },
    iconUrl() {
      return this.$ls.get("navList")[1].iconUrl;
    },
    list() {
      this.gradeList = this.$ls.get("navList")[1].children[0].children;
      return this.$ls.get("navList")[1].children;
    },
  },
  data() {
    return {
      active: 0,
      gradeList: [],
    };
  },
  methods: {
    //学科选择
    select(item, index) {
      this.active = index;
      this.gradeList = item.children;
    },
    //更多教案
    more() {
      let navList = this.$ls.get("navList");
      this.$ls.set("identifier", "teaching-plan", 60 * 60 * 1000 * 24);
      let navOneId = navList.find((r) => r.identifier === "teaching-plan").id;
      this.$ls.set("navOneId", navOneId, 60 * 60 * 1000 * 24);
      this.$ls.set("subjectId", null);
      this.$ls.set("gradeId", null);
      this.$ls.set("search", null);
      this.$router.push({
        path: "/secondary-details",
        query: { type: "teaching-plan" },
      });
    },
    //去详情页（二级）
    goDetail(item) {
      let navList = this.$ls.get("navList");
      this.$ls.set("identifier", "teaching-plan", 60 * 60 * 1000 * 24);
      let navOneId = navList.find((r) => r.identifier === "teaching-plan").id;
      this.$ls.set("navOneId", navOneId, 60 * 60 * 1000 * 24);
      this.$ls.set("subjectId", item.pid, 60 * 60 * 1000 * 24);
      this.$ls.set("gradeId", item.id, 60 * 60 * 1000 * 24);
      this.$ls.set("search", null);
      this.$router.push({
        path: "/secondary-details",
        query: { type: "teaching-plan" },
      });
    },
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.teaching-plan-container {
  width: 100%;
  padding: 36px 120px 0px;
  .title-select {
    width: 100%;
    height: 40px;
    .line {
      width: 6px;
      height: 32px;
      background: @main-color;
      opacity: 1;
      border-radius: 3px;
    }
    .title {
      font-size: 30px;
      font-weight: 400;
      line-height: 40px;
      color: @main-color;
      opacity: 1;
      margin-left: 12px;
    }
    .grade {
      margin-top: 12px;
      .select {
        height: 31px;
        padding: 0 15px;
        margin-left: 12px;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        color: #333333;
        cursor: pointer;
      }
      .select-checked {
        height: 31px;
        padding: 0 15px;
        margin-left: 12px;
        background-image: url("~@/assets/image/boutique_select.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        color: @main-color;
        cursor: pointer;
      }
      .more {
        margin-left: 12px;
        width: 72px;
        height: 24px;
        background: #ffffff;
        border: 1px solid @main-color;
        opacity: 1;
        border-radius: 2px;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        color: @main-color;
        cursor: pointer;
      }
    }
  }
  .content {
    width: 100%;
    height: 426px;
    background: #fff;
    margin-top: 12px;
    padding: 8px 0px 32px 24px;
    box-sizing: border-box;
    align-content: flex-start;
    .mian-img {
      margin-top: 24px;
      width: 333px;
    }
    .right {
      margin-left: 79px;
      width: 764px;
      .content-item {
        margin-top: 24px;
        margin-right: 27px;
        width: 100px;
        height: 170px;
        .other-img {
          width: 100%;
          height: 138px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #333333;
          margin-top: 12px;
          text-align: center;
          width: 100%;
          height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
