<template>
  <div class="container" v-if="show">
    <page-nav></page-nav>
    <search></search>
    <banner></banner>
    <boutique></boutique>
    <test-questions></test-questions>
    <teaching-plan></teaching-plan>
    <ppt></ppt>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageNav from "@/components/PageNav/index.vue";
import Search from "@/views/search/index.vue";
import Banner from "@/views/banner/index.vue";
import Boutique from "@/views/boutique/index.vue";
import TestQuestions from "@/views/test-questions/index.vue";
import TeachingPlan from "@/views/teaching-plan/index.vue";
import Ppt from "@/views/ppt/index.vue";
import PageFooter from "@/components/PageFooter/index.vue";
import { getUserInfo } from "@/axios/acl.js";
export default {
  components: {
    PageNav,
    Search,
    Banner,
    Boutique,
    TestQuestions,
    TeachingPlan,
    Ppt,
    PageFooter,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    //得到导航列表
    getNavList() {
      this.$api.getNavList().then((res) => {
        if (res.code === 200) {
          this.$ls.set(
            "navList",
            this.handleTree(res.data, "id", "pid"),
            60 * 60 * 1000 * 24
          );
          this.show = true;
        }
      });
    },
    //处理树状数据结构
    handleTree(data, id, parentId, children) {
      let config = {
        id: id || "id",
        parentId: parentId || "parentId",
        childrenList: children || "children",
      };

      var childrenListMap = {};
      var nodeIds = {};
      var tree = [];

      for (let d of data) {
        let parentId = d[config.parentId];
        if (childrenListMap[parentId] == null) {
          childrenListMap[parentId] = [];
        }
        nodeIds[d[config.id]] = d;
        childrenListMap[parentId].push(d);
      }

      for (let d of data) {
        let parentId = d[config.parentId];
        if (nodeIds[parentId] == null) {
          tree.push(d);
        }
      }

      for (let t of tree) {
        adaptToChildrenList(t);
      }

      function adaptToChildrenList(o) {
        if (childrenListMap[o[config.id]] !== null) {
          o[config.childrenList] = childrenListMap[o[config.id]];
        }
        if (o[config.childrenList]) {
          for (let c of o[config.childrenList]) {
            adaptToChildrenList(c);
          }
        }
      }
      return tree;
    },
    // 获取cookie
    getCookie(key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    //获取用户信息
    getUserInfo() {
      getUserInfo("acl/index/info").then((res) => {
        if (res.success) {
          this.$ls.set("userInfo", res.data, 60 * 60 * 1000 * 24);
          // console.log(res.data);
        }
      });
    },
    //得到地址栏参数
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
  created() {
    let token = this.getCookie("token");
    let variable = this.getQueryVariable("token");
    if (variable === false) {
      this.$ls.set("token", token);
    } else {
      this.$ls.set("token", variable);
    }
    this.getNavList();
    this.getUserInfo();
  },
};
</script>

<style scoped lang="less">
.container {
  width: 1440px;
  background: #f1f3f5;
}
</style>
