<template>
  <div class="banner-container">
    <div class="tab d-f">
      <div class="tab-one">
        <a-radio-group v-model="value" button-style="solid" @change="onChange">
          <a-radio-button
            :value="item.id"
            class="btn"
            v-for="item in navList"
            :key="item.id"
          >
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="tab-two">
        <a-radio-group v-model="valueToo" button-style="solid" @change="onChangeToo">
          <a-radio-button value="index" class="btn-other"> 首页 </a-radio-button>
          <a-radio-button value="video" class="btn-other"> 视频专区 </a-radio-button>
          <a-radio-button value="teaching-material" class="btn-other">
            教材频道
          </a-radio-button>
          <a-radio-button value="outside" class="btn-other">
            国家教育资源平台
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <!-- 轮播图 -->
    <a-carousel autoplay>
      <div v-for="item in bannerList" :key="item.id" @click="toHotNews(item.id)">
        <img :src="item.imageUrl" alt="" />
      </div>
    </a-carousel>
    <!-- v-if="bannerList.length > 1" -->
    <!-- <div v-else class="carousel-other">
      <div v-for="item in bannerList" :key="item.id" @click="toHotNews(item.id)">
        <img :src="item.imageUrl" alt="" />
      </div>
    </div> -->
    <ul>
      <li
        v-for="(item, index) in liList"
        :key="item.id"
        @mouseenter="selectStyle(item)"
        @mouseleave="outStyle(item)"
      >
        <div class="d-f j-b a-c li-con">
          <div class="category d-f a-c">
            <img :src="item.iconUrl" alt="" />
            <span>{{ item.name }}</span>
          </div>
          <img src="@/assets/image/banner_arrow.png" alt="" v-if="!item.active" />
          <img src="@/assets/image/banner_arrow_hover.png" alt="" v-else />
        </div>
        <div class="line" v-if="index != liList.length - 1"></div>
        <div class="li-hover" v-if="item.active && item.children != null">
          <div class="hover-con">
            <div class="hover-con-content d-f">
              <div
                class="hover-con-content-item"
                v-for="it in item.children"
                :key="it.id"
                @click="goDetail(item, it)"
              >
                {{ it.name }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
      value: "",
      liList: [],
      valueToo: "index",
      active: false,
    };
  },
  computed: {
    navList() {
      this.liList = this.$ls.get("navList")[0].children;
      this.value = this.$ls.get("navList")[0].id;
      return this.$ls.get("navList").filter((r) => r.status === "0");
    },
  },
  methods: {
    //类型改变
    onChange(e) {
      let value = e.target.value;
      let arr = this.$ls.get("navList");
      let arr1 = arr.filter((r) => r.id === value)[0].children;
      this.liList = arr1;
    },
    //视频教材tab改变
    onChangeToo(e) {
      this.valueToo = e.target.value;
      if (this.valueToo == "outside") {
        window.open("https://www.smartedu.cn/", "_blank");
      } else if (this.valueToo == "index") {
        this.$router.push("/index");
      } else {
        let navList = this.$ls.get("navList");
        this.$ls.set("identifier", this.valueToo, 60 * 60 * 1000 * 24);
        let navOneId = navList.find((r) => r.identifier === this.valueToo).id;
        this.$ls.set("navOneId", navOneId, 60 * 60 * 1000 * 24);
        this.$ls.set("subjectId", null);
        this.$ls.set("gradeId", null);
        this.$ls.set("search", null);

        this.$router.push({
          path: "/secondary-details",
          query: { type: this.valueToo },
        });
      }
    },
    //li鼠标移入
    selectStyle(item) {
      var _this = this;
      this.$nextTick(function () {
        this.liList.forEach(function (item) {
          _this.$set(item, "active", false);
        });
        this.$set(item, "active", true);
      });
    },
    //li鼠标移出
    outStyle(item) {
      this.$set(item, "active", false);
    },
    //导航栏去详情页（二级）
    goDetail(item, it) {
      let obj = this.navList.find((r) => r.id === item.pid);
      this.$ls.set("identifier", obj.identifier);
      this.$ls.set("navOneId", item.pid, 60 * 60 * 1000 * 24);
      this.$ls.set("subjectId", item.id, 60 * 60 * 1000 * 24);
      this.$ls.set("gradeId", it.id, 60 * 60 * 1000 * 24);
      this.$ls.set("search", null);
      this.$router.push({
        path: "/secondary-details",
        query: { type: obj.identifier },
      });
    },
    //得到轮播图片
    getBanner() {
      this.$api.getBanner().then((res) => {
        if (res.code === 200) {
          this.bannerList = res.data;
        } else {
          // console.log(res);
          this.$message.error(res.msg);
        }
      });
    },
    //去热点资讯
    toHotNews(bannerId) {
      this.$router.push({
        path: "/hot-news",
        query: { bannerId },
      });
    },
  },
  created() {
    this.getBanner();
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.banner-container {
  width: 1440px;
  position: relative;
  .tab {
    width: 100%;
    height: 48px;
    padding: 0 120px;
    box-sizing: border-box;
    background: @main-color;
    .tab-one {
      .ant-radio-button-wrapper {
        color: rgba(256, 256, 256, 0.5);
        border: none;
        border-radius: 0px;
        background: #057e42;
      }
      .ant-radio-button-wrapper-checked {
        color: rgba(256, 256, 256, 1);
        border: none;
        border-radius: 0px;
        box-shadow: none;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        width: 0;
      }
      .btn {
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        border-radius: 0px;
        padding: 0 20px;
      }
    }
    .tab-two {
      .ant-radio-button-wrapper {
        color: rgba(256, 256, 256, 1);
        border: none;
        background: @main-color;
        border-radius: 0px;
      }
      .ant-radio-button-wrapper-checked {
        border: none;
        background: #058f39;
        border-radius: 0px;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        width: 0;
      }
      .btn-other {
        width: 144px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        border-radius: 0px;
      }
    }
  }
  .ant-carousel {
    width: 100%;
    height: 495px;
    ::v-deep .slick-slide {
      pointer-events: auto;
      img {
        width: 100%;
        height: 495px;
      }
    }
  }
  .carousel-other {
    width: 100%;
    height: 495px;
    overflow: hidden;
    img {
      width: 100%;
      height: 495px;
    }
  }
  ul {
    position: absolute;
    width: 227px;
    height: 495px;
    background: #ffffff;
    top: 48px;
    left: 120px;
    box-sizing: border-box;
    li {
      width: 100%;
      padding: 0 13px;
      position: relative;
      .li-con {
        width: 100%;
        height: 37px;
        img {
          width: 12px;
        }
        .category {
          img {
            width: 16px;
          }
          span {
            margin-left: 8px;
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .line {
        width: 190px;
        height: 1px;
        background: #eeeeee;
      }
      .li-hover {
        position: absolute;
        top: 0px;
        left: 227px;
        padding: 24px 24px 12px 24px;
        box-sizing: border-box;
        background: #ffffff;
        border: 1px solid @main-color;
        box-shadow: 0px 0px 6px rgba(14, 52, 0, 0.1);
        opacity: 1;
        border-radius: 4px;
        .hover-con {
          cursor: pointer;
          width: 100%;
          .hover-con-content {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #999999;
            opacity: 1;
            .hover-con-content-item {
              padding-right: 24px;
              padding-bottom: 12px;
              white-space: nowrap;
            }
            .hover-con-content-item:hover {
              color: @main-color;
            }
          }
        }
        .hover-con:last-child {
          border-bottom: none;
        }
      }
    }
    li:hover {
      color: @main-color;
    }
  }
}
</style>
